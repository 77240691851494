/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * DB table name
 */
export enum ENTITY_NAME {
  CASINO_GAMES_3 = 'casino_games_3',
  CASINO_CATEGORIES_3 = 'casino_categories_3',
  CASINO_GAMES_CATEGORIES_3 = 'casino_games_categories_3',
  CASINO_CATEGORIES_LOCALE_SETTINGS = 'casino_categories_locale_settings',
  CASINO_CATEGORY_PRESETS = 'casino_category_presets',
  CASINO_GAMES_LOCALE_SETTINGS = 'casino_games_locale_settings',
  CATEGORIES_FOR_RANDOM_GAME = 'categories_for_random_game',
  GAME_OF_WEEK = 'game_of_week',
  GAME_OF_WEEK_LOCALES = 'game_of_week_locales',
  LOCALES = 'locales',
  GAME_PROVIDER_SETTINGS = 'game_provider_settings',
  PROMO = 'promo',
  PROMO_NOMINATIONS = 'promo_nominations',
  PROMO_DEPOSITS = 'promo_deposits',
  PROMO_EVENTS = 'promo_events',
  USERS = 'users',
  PROVIDER_CONFIGS = 'provider_configs',
  CASINO_GAMES_SETTINGS = 'casino_games_settings',
  CASINO_CATEGORIES_SETTINGS = 'casino_categories_settings',
  API_KEYS = 'api_keys',
}
