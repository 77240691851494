/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PROVIDER {
  FUNDIST = 'fundist',
  EVOPLAY = 'evoplay',
  SOFTGAMING = 'softgaming',
  SPINOMENAL = 'spinomenal',
  ELK = 'elk',
  TVBET = 'tvbet',
  MRSLOTTY = 'mrslotty',
  _1X2 = '1x2',
  HABANERO = 'habanero',
  CUBEIA = 'cubeia',
  SPINMATIC = 'spinmatic',
  PRAGMATIC = 'pragmatic',
  BGAMING = 'bgaming',
  SPRIBE = 'spribe',
  PARIPLAY = 'pariplay',
  INFINGAMES = 'infingames',
  BFGAMES = 'bfgames',
  ENDORPHINA = 'endorphina',
  WM = 'wm',
  RELAX = 'relax',
  THANUS = 'thanus',
  _100HP = '100hp',
  _1PLAY = '1play',
  BETGAMES = 'betgames',
  EVENBET = 'evenbet',
  BETERLIVE = 'beterlive',
  SCOUT = 'scout',
  ATMOSFERA = 'atmosfera',
  WAC = 'wac',
  SOFTSWISS = 'softswiss',
  TGG = 'tgg',
  AVIATRIX = 'aviatrix',
  VINFOTECH = 'vinfotech',
  ORCHESTRA = 'orchestra',
}
