import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Layout.vue';
import { ROUTE } from '@/router/routes-enum';
import { RIGHT } from '@/api';
import { RIGHTS_RESOLVE_STRATEGY } from '@/constants/rights';

export const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    component: Layout,
    meta: { showInMenu: true },
    children: [
      {
        path: '',
        component: () => import('@/views/About.vue'),
        name: ROUTE.DASHBOARD,
        meta: { title: 'Dashboard', icon: 'grid', showInMenu: true },
      },
    ],
  },
  {
    path: '/login',
    name: ROUTE.LOGIN,
    component: () => import('@/views/Login.vue'),
  },

  {
    path: '/404',
    name: ROUTE.ERR_404,
    component: () => import('@/views/errors-pages/404.vue'),
  },

  {
    path: '/500',
    name: ROUTE.ERR_500,
    component: () => import('@/views/errors-pages/500.vue'),
  },
];

export const protectedRoutes: RouteRecordRaw[] = [
  // пример использования вложенного меню:
  // {
  //   path: '/nested',
  //   component: Layout,
  //   meta: {
  //     title: 'Nested Menu', icon: 'edit', showInMenu: true,
  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/About.vue'),
  //       name: 'Nested',
  //       meta: {
  //         title: 'Nested', icon: 'edit', showInMenu: true,
  //       },
  //     },
  //     {
  //       path: 'index2',
  //       component: () => import('@/views/About.vue'),
  //       name: 'Nested2',
  //       meta: { title: 'Nested2', icon: 'edit', showInMenu: true },
  //     },
  //   ],
  // },
  {
    path: '/profile',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/profile/Profile.vue'),
        name: ROUTE.PROFILE,
      },
    ],
  },
  {
    path: '/changelogs',
    component: Layout,
    meta: { title: 'Changelogs', icon: 'management', showInMenu: true },
    children: [
      {
        path: '',
        component: () => import('@/views/changelogs/Changelogs.vue'),
        name: ROUTE.CHANGELOGS,
        meta: {
          title: 'Changelogs',
          showInMenu: true,
          icon: 'management',
          rights: [RIGHT.READ_CHANGELOGS],
        },
      },
      {
        path: ':changelogId',
        name: ROUTE.CHANGELOG,
        meta: { showInMenu: false, rights: [RIGHT.READ_CHANGELOGS] },
        component: () => import('@/views/changelogs/Changelog.vue'),
      },
    ],
  },
  {
    path: '/users',
    component: Layout,
    meta: { showInMenu: true },
    children: [
      {
        path: '',
        component: () => import('@/views/users/Users.vue'),
        name: ROUTE.USERS,
        meta: {
          title: 'Users', icon: 'user-filled', showInMenu: true, rights: [RIGHT.MANAGE_USERS],
        },
      },
    ],
  },
  {
    path: '/games',
    component: Layout,
    meta: { title: 'Games', icon: 'coordinate', showInMenu: true },
    children: [
      {
        path: '',
        component: () => import('@/views/games/Games.vue'),
        name: ROUTE.GAMES,
        meta: {
          title: 'Games',
          showInMenu: true,
          icon: 'coordinate',
          rights: [RIGHT.READ_GAMES],
        },
      },
      {
        path: 'new',
        component: () => import('@/views/games/GameNew.vue'),
        name: ROUTE.GAME_NEW,
        meta: {
          title: 'Create',
          showInMenu: true,
          icon: 'circle-plus',
          rights: [RIGHT.CREATE_GAMES],
        },
      },
      {
        path: 'edit/:id',
        component: () => import('@/views/games/GameEdit.vue'),
        name: ROUTE.GAME_EDIT,
        meta: {
          title: 'Edit the game',
          rights: [RIGHT.UPDATE_GAMES],
          showInMenu: false,
        },
      },
      {
        path: 'launch-url',
        component: () => import('@/views/games/GameLaunchUrl.vue'),
        name: ROUTE.GAME_LAUNCH_URL,
        meta: {
          title: 'Get URL',
          showInMenu: true,
          icon: 'link',
          rights: [RIGHT.READ_GAME_LAUNCH_URL],
        },
      },
      {
        path: 'game-of-week',
        component: () => import('@/views/games/GameOfWeekList.vue'),
        name: ROUTE.GAME_OF_WEEK_LIST,
        meta: {
          title: 'Game of week',
          showInMenu: true,
          icon: 'flag',
          rights: [RIGHT.READ_GAME_OF_WEEK],
        },
      },
      {
        path: 'game-of-week/new',
        component: () => import('@/views/games/GameOfWeekNew.vue'),
        name: ROUTE.GAME_OF_WEEK_NEW,
        meta: {
          title: 'Create game of week',
          rights: [RIGHT.CREATE_GAME_OF_WEEK],
          showInMenu: false,
        },
      },
      {
        path: 'game-of-week/edit/:id',
        component: () => import('@/views/games/GameOfWeekEdit.vue'),
        name: ROUTE.GAME_OF_WEEK_EDIT,
        meta: {
          title: 'Edit game of week',
          rights: [RIGHT.UPDATE_GAME_OF_WEEK],
          showInMenu: false,
        },
      },
    ],
  },
  {
    path: '/promo',
    component: Layout,
    meta: { title: 'Promo', showInMenu: true, icon: 'sugar' },
    children: [
      {
        path: '',
        component: () => import('@/views/promo/Promo.vue'),
        name: ROUTE.PROMO,
        meta: {
          title: 'Promo',
          showInMenu: true,
          icon: 'sugar',
          rights: [RIGHT.READ_PROMO],
        },
      },
      {
        path: 'nominations',
        component: () => import('@/views/promo/PromoNominations.vue'),
        name: ROUTE.PROMO_NOMINATIONS,
        meta: {
          title: 'Nominations',
          showInMenu: true,
          icon: 'flag',
          rights: [RIGHT.READ_PROMO_NOMINATIONS],
        },
      },
      {
        path: 'deposits',
        component: () => import('@/views/promo/PromoDeposits.vue'),
        name: ROUTE.PROMO_DEPOSITS,
        meta: {
          title: 'Deposits',
          showInMenu: true,
          icon: 'wallet-filled',
          rights: [RIGHT.READ_PROMO_DEPOSITS],
        },
      },
      {
        path: 'logs',
        component: () => import('@/views/promo/PromoLogs.vue'),
        name: ROUTE.PROMO_LOGS,
        meta: {
          title: 'Logs',
          showInMenu: true,
          icon: 'tickets',
          rights: [RIGHT.READ_PROMO_LOGS],
        },
      },
      {
        path: 'statistics/:promoId',
        component: () => import('@/views/promo/PromoStatistics.vue'),
        name: ROUTE.PROMO_STATISTICS,
        meta: {
          title: 'Statistics', showInMenu: false, rights: [RIGHT.READ_PROMO_STATISTICS],
        },
      },
      {
        path: ':promoId',
        name: ROUTE.ONE_PROMO,
        meta: { showInMenu: false, rights: [RIGHT.READ_PROMO] },
        component: () => import('@/views/promo/OnePromo.vue'),
      },
    ],
  },
  {
    path: '/providers',
    component: Layout,
    meta: {
      title: 'Providers', icon: 'shop', showInMenu: true, pinAsSubMenu: true,
    },
    children: [
      {
        path: 'provider-configs',
        component: () => import('@/views/providers/GameConfigs.vue'),
        name: ROUTE.PROVIDER_GAME_CONFIGS,
        meta: {
          title: 'Provider configs',
          showInMenu: true,
          icon: 'setting',
          rights: [RIGHT.MANAGE_FEED_READER_CONFIGS],
        },
      },
      {
        path: 'provider-configs/:providerKey/:configKey?',
        component: () => import('@/views/providers/GameConfig.vue'),
        name: ROUTE.PROVIDER_GAME_CONFIG,
        meta: {
          title: 'General game config',
          showInMenu: false,
          rights: [RIGHT.MANAGE_FEED_READER_CONFIGS],
        },
      },
      {
        path: 'game-logs',
        component: () => import('@/views/providers/GameLoaderLogs.vue'),
        name: ROUTE.PROVIDER_GAMES_LOGS,
        meta: {
          title: 'Game loader logs',
          showInMenu: true,
          icon: 'list',
          rights: [RIGHT.READ_FEED_READER_LOGS],
        },
      },
      {
        path: 'api-call',
        component: () => import('@/views/providers/ApiCall.vue'),
        name: ROUTE.PROVIDER_API_CALL,
        meta: {
          title: 'API call',
          showInMenu: true,
          icon: 'document',
          rights: [RIGHT.MAKE_PROVIDER_API_CALL],
        },
      },
      {
        path: 'game-sessions',
        component: () => import('@/views/providers/GameSessions.vue'),
        name: ROUTE.PROVIDER_GAME_SESSIONS,
        meta: {
          title: 'Game sessions',
          showInMenu: true,
          icon: 'ticket',
          rights: [RIGHT.READ_GAME_SESSION_DATA],
        },
      },
      {
        path: 'game-sessions/search',
        component: () => import('@/views/providers/GameSession.vue'),
        name: ROUTE.PROVIDER_GAME_SESSION,
        meta: {
          title: 'Session search',
          showInMenu: true,
          icon: 'aim',
          rights: [RIGHT.READ_GAME_SESSION_DATA],
        },
      },
      {
        path: 'display-names',
        component: () => import('@/views/providers/ProviderDisplayNames.vue'),
        name: ROUTE.PROVIDER_DISPLAY_NAMES,
        meta: {
          title: 'Display names',
          showInMenu: true,
          icon: 'guide',
          rights: [RIGHT.MANAGE_PROVIDER_SETTINGS],
        },
      },
    ],
  },
  {
    path: '/widgets',
    component: Layout,
    meta: { showInMenu: true },
    children: [
      {
        path: '',
        component: () => import('@/views/widgets/Widgets.vue'),
        name: ROUTE.WIDGETS,
        meta: {
          title: 'Widgets',
          icon: 'postcard',
          showInMenu: true,
          rights: [RIGHT.MANAGE_RANDOM_GAME_CATEGORY],
          // так как на этой странице будут разные виджеты,
          // то нужно право хотя-бы на один из них
          rightsResolveStrategy: RIGHTS_RESOLVE_STRATEGY.SOME,
        },
      },
    ],
  },
  {
    path: '/categories',
    name: ROUTE.GAME_CATEGORIES_WRAP,
    component: Layout,
    meta: {
      showInMenu: true,
      icon: 'menu',
      title: 'Categories',
      pinAsSubMenu: true,
    },
    children: [
      {
        path: ':presetId?',
        name: ROUTE.GAME_CATEGORIES,
        component: () => import('@/views/categories/Categories.vue'),
        meta: {
          title: 'Categories',
          showInMenu: false,
          rights: [RIGHT.MANAGE_CATEGORY_LOCALE_SETTINGS],
        },
      },
    ],
  },
  {
    path: '/dev',
    component: Layout,
    meta: {
      title: 'Dev', icon: 'cpu', showInMenu: true, pinAsSubMenu: true,
    },
    children: [
      {
        path: 'keys',
        component: () => import('@/views/dev/ApiKeys.vue'),
        name: ROUTE.API_KEYS,
        meta: {
          title: 'API-keys',
          showInMenu: true,
          icon: 'key',
          rights: [RIGHT.MANAGE_API_KEYS],
        },
      },
      {
        path: 'keys/new',
        component: () => import('@/views/dev/NewApiKey.vue'),
        name: ROUTE.NEW_API_KEY,
        meta: {
          title: 'New API-key',
          showInMenu: false,
          icon: 'key',
          rights: [RIGHT.MANAGE_API_KEYS],
        },
      },
    ],
  },
  // 404 page must be placed at the end !!!
  { path: '/:catchAll(.*)', redirect: '/404' },
];
