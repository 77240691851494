/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreatePromoDto = {
  title: string;
  startsAt: string;
  endsAt: string;
  params: {
    gameProvider?: CreatePromoDto.gameProvider;
    gameCategoriesIds?: Array<number>;
    gameIds?: Array<string>;
    promoMaxWinAmount?: number;
    promoMaxWinCurrency?: string;
    userMaxWinLimit?: number;
    spinCurrency?: string;
    spinAmount?: number;
    betLevel?: string;
    freeSpinCount?: number;
    freeSpinTtlInMinutes?: number;
    promoMaxUsersLimit?: number;
    promoMaxFreespinsLimit?: number;
    wager?: number;
    promoDepositTtlInMinutes?: number;
  };
  eventsConditions: Record<string, Record<string, (number | Array<number> | string | Array<string> | boolean | Array<boolean>)>>;
  isActive?: boolean;
  isTest?: boolean;
  isManual?: boolean;
  showInCashier?: boolean;
};
export namespace CreatePromoDto {
  export enum gameProvider {
    FUNDIST = 'fundist',
    PRAGMATIC = 'pragmatic',
    SPRIBE = 'spribe',
    INFINGAMES = 'infingames',
    ENDORPHINA = 'endorphina',
  }
}

